import { RouteRecordRaw } from "vue-router";

export const MigrationUtitity: Array<RouteRecordRaw> = [
  {
    path: "/migration-utility",
    name: "migration-utility-index",
    component: () => import("@/views/migration-utility/MigrationUtilityIndex.vue"),
  },
];
export default MigrationUtitity;
