import { createApp } from "vue";
import App from "@/App.vue";
import { router } from "@/router";
import store from "@/store";
import axios from "axios";
import "@/helpers/validators";
import filters from "@/helpers/filters";
import { i18n } from "@/helpers/i18nSet";
import "@/core/plugins/prismjs";
import elementPlus from "element-plus";
import VueApexCharts from "vue3-apexcharts";
import permission from "@/helpers/permissions.js";
import { gsap } from "gsap";
import JsonCSV from "vue-json-csv";
import * as Sentry from "@sentry/vue";

import i18nTfuncWrapper from "@/composables/i18nTfuncWrapper";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

//DataTable + in shims-vue.d.ts
import VueGoodTablePlugin from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

import { initInlineSvg } from "@/core/plugins/inline-svg";

axios.defaults.baseURL =
  location.host === "frontend:8080" ? process.env.VUE_APP_BASE_API_CYPRESS : process.env.VUE_APP_BASE_API;

const app = createApp(App);

//NOTE: global types
declare global {
  interface Window {
    __store__: typeof store;
    Cypress?: any;
  }
}

if (window.Cypress) {
  window.__store__ = store;
}

app.use(store);
app.use(router);
app.use(i18n);

i18n.global.t = i18nTfuncWrapper();
app.use(Toast);
app.use(elementPlus);
app.use(VueGoodTablePlugin);
app.use(VueApexCharts);
app.use(gsap);
app.use(JsonCSV);
app.directive("permission", permission);

initInlineSvg(app);

app.config.globalProperties.$filters = filters;

if (process.env.VUE_APP_ENVIRONMENT === "PROD") {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: 0.25,
    environment: "production",
    tracePropagationTargets: [process.env.VUE_APP_BASE_API || ""],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  console.log("Sentry has been initialized");
}

app.config.globalProperties.$t = i18nTfuncWrapper();
app.mount("#app");
