import { RouteRecordRaw } from "vue-router";

export const Error: Array<RouteRecordRaw> = [
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/layout/default/Layout.vue"),
    children: [
      {
        path: "",
        name: "NotFound",
        component: () => import("@/views/error/Error404.vue"),
        meta: { hideHeadliner: true },
      },
    ],
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/layout/default/Layout.vue"),
    children: [
      {
        path: "",
        name: "InternalError",
        component: () => import("@/views/error/Error500.vue"),
        meta: { hideHeadliner: true },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
export default Error;
