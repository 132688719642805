import { RouteRecordRaw } from "vue-router";

export const EmailTemplate: Array<RouteRecordRaw> = [
  {
    path: "/email-templates",
    name: "email-templates",
    component: () => import("@/views/email/email-template/EmailTemplate.vue"),
    meta: { resources: ["mailTemplate"] },
    children: [
      {
        path: "/email-templates",
        name: "email-templates-index",
        component: () => import("@/views/email/email-template/EmailTemplateIndex.vue"),
        meta: { resources: ["mailTemplate"] },
      },
      {
        path: "/email-templates/create",
        name: "email-templates-create",
        component: () => import("@/views/email/email-template/EmailTemplateCreate.vue"),
        meta: { resources: ["mailTemplate"] },
      },
      {
        path: "/email-templates/detail/:id",
        name: "email-templates-show",
        component: () => import("@/views/email/email-template/EmailTemplateShow.vue"),
        meta: { resources: ["mailTemplate"] },
      },
      {
        path: "/email-templates/edit/:id",
        name: "email-templates-edit",
        component: () => import("@/views/email/email-template/EmailTemplateEdit.vue"),
        meta: { resources: ["mailTemplate"] },
      },
    ],
  },
  {
    path: "/email-templates-module",
    name: "email-templates-module",
    component: () => import("@/views/email/email-template-module/EmailTemplateModule.vue"),
    meta: { resources: ["mailTemplate"] },
    children: [
      {
        path: "/email-templates-module",
        name: "email-templates-module-index",
        component: () => import("@/views/email/email-template-module/EmailTemplateModuleIndex.vue"),
        meta: { resources: ["mailTemplate"] },
      },
      {
        path: "/email-templates-module/create",
        name: "email-templates-module-create",
        component: () => import("@/views/email/email-template-module/EmailTemplateModuleCreate.vue"),
        meta: { resources: ["mailTemplate"] },
      },
      {
        path: "/email-templates-module/detail/:id",
        name: "email-templates-module-show",
        component: () => import("@/views/email/email-template-module/EmailTemplateModuleShow.vue"),
        meta: { resources: ["mailTemplate"] },
      },
      {
        path: "/email-templates-module/edit/:id",
        name: "email-templates-module-edit",
        component: () => import("@/views/email/email-template-module/EmailTemplateModuleEdit.vue"),
        meta: { resources: ["mailTemplate"] },
      },
    ],
  },
];
export default EmailTemplate;
