import { RouteRecordRaw } from "vue-router";

export const Transaction: Array<RouteRecordRaw> = [
  {
    path: "/transactions",
    name: "transactions-index",
    component: () => import("@/views/transaction/transaction-management/TransactionManagementIndex.vue"),
    meta: { resources: ["Transaction"] },
  },
  {
    path: "/transactions/clicks",
    name: "transactions.clicks",
    component: () => import("@/views/click/click-management/ClickManagementIndex.vue"),
    meta: { resources: ["Click"] },
  },
  {
    path: "/transactions/clicks-error",
    name: "transactions.clicks_error",
    component: () => import("@/views/click/click-management/ClickErrorManagementIndex.vue"),
    meta: { resources: ["redirLog"] },
  },
  {
    path: "/transactions/transactions-raw",
    name: "transactions.transactions-raw",
    component: () => import("@/views/transaction/transaction-raw-management/TransactionRawManagementIndex.vue"),
    meta: { resources: ["RawTransaction"] },
  },
  {
    path: "/transactions-raw",
    name: "transactions-raw",
    component: () => import("@/views/transaction/transaction-raw-management/TransactionRawManagement.vue"),
    meta: { resources: ["RawTransaction"] },
    children: [
      {
        path: "",
        name: "transactions-raw-index",
        component: () => import("@/views/transaction/transaction-raw-management/TransactionRawManagementIndex.vue"),
        meta: { resources: ["RawTransaction"] },
      },
    ],
  },
  {
    path: "/transactions-raw/import",
    name: "transactions.import",
    component: () => import("@/views/transaction/transaction-raw-management/TransactionRawImport.vue"),
    meta: { resources: ["RawTransactionImport"] },
  },
];
export default Transaction;
