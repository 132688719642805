import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import Auth from "./auth.routes";
import Page from "./page.routes";
import Dashboard from "./dashboard.routes";
import Error from "./error.routes";
import User from "@/router/user.routes";
import Translation from "@/router/translation.routes";
import Click from "@/router/click.routes";
import EmailTemplate from "@/router/email-template.routes";
import Transaction from "@/router/transaction.routes";
import Campaign from "@/router/campaign.routes";
import Account from "@/router/account.routes";
import CampaignPartner from "@/router/partner.routes";
import Mail from "@/router/mail.routes";
import File from "@/router/file.routes";
import Report from "@/router/report.routes";
import Log from "@/router/log.routes";
import Commission from "@/router/commission.routes";
import Automation from "@/router/automation.routes";
import AdChannel from "@/router/ad-channel.routes";
import CampaignFeed from "@/router/feed.routes";
import Payment from "@/router/payment.routes";
import Coupon from "@/router/coupon.routes";
import GenerateLink from "@/router/generate-link.routes";
import MigrationUtility from "@/router/migration-utility.routes";
let authRoutes: any[] = [];
let errorRoutes: any[] = [];
let allRoutes: any[] = [];
authRoutes = authRoutes.concat(Auth, Page);
errorRoutes = errorRoutes.concat(Error);
allRoutes = allRoutes.concat(
  Dashboard,
  Account,
  User,
  Click,
  Translation,
  EmailTemplate,
  Transaction,
  Campaign,
  CampaignPartner,
  Mail,
  File,
  Report,
  Log,
  Commission,
  Campaign,
  File,
  Automation,
  AdChannel,
  CampaignFeed,
  Payment,
  Coupon,
  GenerateLink,
  MigrationUtility
);
/*allRoutes = allRoutes.concat(
  Dashboard,
  User,
  Clicks,
  Translations,
  EmailTemplate,
  Transactions,
  File
);*/

let routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "index",
    component: () => import("@/layout/default/Layout.vue"),
    children: allRoutes,
  },
  {
    path: "/",
    component: () => import("@/layout/default/page-layout/Auth.vue"),
    children: authRoutes,
  },
];
routes = routes.concat(errorRoutes);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let routeMeta = {};

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/reset-password/" + to.params.token + "/" + to.params.email,
    "/create-password/" + to.params.token,
    "/about",
    "/migration",
    "/migration/",
    "/migration/" + to.params.token,
  ];

  const authRequired = !publicPages.includes(to.path);
  //const loggedIn = localStorage.getItem("user");
  const loggedIn = store.state.auth.status.loggedIn;
  routeMeta = to.meta;

  // check if route requires role and if user has that role or not (if roles are set)
  const permissionsToCheck: string[] = (to.meta?.permissions as string[]) || [];

  if (permissionsToCheck.length > 0) {
    const userRoles = store.state.auth.user?.user?.roles ?? null;
    if (!userRoles) {
      return next("/login");
    }

    const hasAccess = userRoles.some((role) =>
      role.permissions.some((permission) => permissionsToCheck.includes(permission.name))
    );

    if (!hasAccess) {
      return next("/"); // Replace with your actual access denied route name
    }
  }

  store.dispatch("routeMeta/setRouteMeta", routeMeta);

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
    //when is loggend in store and try to access / page (backend can be logged out, or token expired)`
  } else if (authRequired && loggedIn && to.path === "/") {
    next("/dashboard");
  } else {
    next();
  }
});

export { router, routeMeta };
